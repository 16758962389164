export const SET_ATTRIBUTE_ROLL_TYPE = 'SET_ATTRIBUTE_ROLL_TYPE';
export const SET_ROLL_IN_ORDER = 'SET_ROLL_IN_ORDER';
export const SET_DISPLAY_HIGH_TO_LOW = 'SET_DISPLAY_HIGH_TO_LOW';
export const SET_FORCE_STALE = 'SET_FORCE_STALE';
export const SET_DISPLAY_MODS = 'SET_DISPLAY_MODS';
export const SET_DISPLAY_DICE = 'SET_DISPLAY_DICE';
export const DO_ROLLOUT = 'DO_ROLLOUT';
export const FAIL_ROLLOUT = 'FAIL_ROLLOUT';
export const ADD_REQUIREMENT = 'ADD_REQUIREMENT';
export const REPLACE_REQUIREMENTS = 'REPLACE_REQUIREMENTS';
export const REMOVE_REQUIREMENT = 'REMOVE_REQUIREMENT';
export const PRESET_COLVILLE = 'PRESET_COLVILLE';
export const PRESET_COLVILLE_CLASSIC = 'PRESET_COLVILLE_CLASSIC';
export const PRESET_MERCER = 'PRESET_MERCER';
export const PRESET_MERCER_PLUS = 'PRESET_MERCER_PLUS';
